/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";

// size in pixels
$html-font-size: 16;
@function rem($pxValue) {
  @return #{$pxValue / $html-font-size}rem;
}

// size in pixels
$fontsizes: (12, 13, 14, 16, 18, 20, 21, 22, 24, 26, 36);

@each $item in $fontsizes {
  .fs-#{$item} {
    font-size: #{rem($item)};
  }
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #5191cf;
  .bo-inner-content {
    background: #fff;
    margin-bottom: 10px;
    padding: 10px 17px;
    border: 1px solid #e6e9ed;
    header {
      border-bottom: 2px solid #e6e9ed;
      padding: 1px 5px 6px;
      margin-bottom: 33px;
      h2 {
        font-size: 1.125rem;
        small {
          color: #777;
          font-size: 65%;
          font-weight: 400;
        }
      }
    }
    main {
      height: auto;
      padding: 5px;
    }
  }

  .bo-page-header {
    h3 {
      display: inline-block;
      font-size: 1.5rem;
      i {
        margin-right: 7px;
      }
      small {
        color: #777;
        font-size: 65%;
        font-weight: 400;
      }
    }
  }

  .bo-page-header-actions {
    i {
      margin-right: 5px;
    }
    .btn-small {
      font-size: 0.75rem;
    }
  }

  .btn-success {
    background: #26b99a;
    border: 1px solid #169f85;
    border-radius: 3px;
    color: #fff;
    font-size: 0.88rem;
    &:hover {
      background: #169f85;
    }
  }

  .btn-primary {
    color: #fff;
    background-color: #337ab7;
    border: 1px solid #2e6da4;
    border-radius: 3px;
    font-size: 0.88rem;
    &:hover {
      background-color: #286090;
      border-color: #204d74;
    }
  }

  input {
    &[type="text"] {
      &.ng-touched {
        &.ng-invalid {
          border: 1px solid red;
        }
      }
    }
  }

  select {
    &.ng-touched {
      &.ng-invalid {
        border: 1px solid red;
      }
    }
  }
}
